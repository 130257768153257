import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const RugbySequicentenaryPayment: React.FC<PageProps> = ({ data }) => (
  <Layout
    removeFooterMargin
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/rugby150/header-alt.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Rugby Sequicentenary"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase drop-shadow-[1px_1px_5px_rgba(0,0,0,.5)] shadow-black">
            <span>Rugby</span>
            <br />
            Sesquicentenary Dinner
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="RBAI Rugby Sesquicentenary Dinner" />

    <div className="container py-12">
      <h1 className="pb-8">RBAI Rugby Sesquicentenary Dinner</h1>
      <p className="text-2xl">
        Dinner Date: Friday 30th September 2022
        <br />
        Venue: Common Hall, RBAI
        <br />
        Time: 7pm for 7.30pm
        <br />
        Dress: Black tie
        <br />
        Price: £50
      </p>
      <p className="text-2xl mt-12">
        <strong>
          Please contact Sharon Casement to purchase tickets or for all
          enquiries:
        </strong>
        <div className="flex gap-4">
          <span>Tel: 028 9024 0461</span>
          <span>
            Email:{" "}
            <a
              className="link font-bold text-dark"
              href="mailto:alumni@rbai.org.uk"
              target="_blank"
            >
              alumni@rbai.org.uk
            </a>
          </span>
        </div>
      </p>
    </div>
  </Layout>
)

export default RugbySequicentenaryPayment
